<template>
  <div>
    <section class="mt-0 review" v-if="blog">
      <b-row class="">
        <b-col class="ml-5 mr-5">
          <div class="image-with-text ml-5 mr-5">
            <b-img
              :src="blog['banner_image']"
              alt="Circle image"
              class="w-100 border-radius-8"
              height="400"
            ></b-img>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="text-left ml-5 mr-5">
          <div class="ml-5 mr-5">
            <router-link
              to="/blog"
              class="font-size-12"
              style="text-decoration: none"
            >
              blog / <span class="text-muted">blog detail</span></router-link
            >

            <h3 class="section-subtitle pt-2 section3-title">
              {{ blog["title"] }}
            </h3>
            <p class="font-size-14 text-justify">
              {{ blog["title"] }}
              <span class="m-3">{{ blog["postdate"] | formatDate }}</span>
            </p>
            <p
              class="fon-size-14 mt-5 text-justify"
              v-html="blog['description']"
            ></p>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "blogdetail",
  data() {
    return {
      blog: null,
    };
  },
  mounted() {
    this.getblogdetails();
  },
  methods: {
    ...mapActions(["getBlogDetail"]),
    async getblogdetails() {
      await this.getBlogDetail({ id: this.$route.params.id }).then(
        (response) => {
          this.blog = response.data.data;
        }
      );
    },
  },
};
</script>
